.trajectory-item {
  display: flex;
  align-items: center; /* Alinea verticalmente los elementos */
  margin-bottom: 20px; /* Espacio entre los elementos */
  transition: background-color 0.3s ease, border 0.3s ease; /* Suaviza la transición */
  padding: 10px 10px 0 10px; /* Arriba, derecha, abajo, izquierda */

}
.trajectory-item:hover {
  background-color: #17264B; /* Color de fondo al hacer hover */
  border-radius: 5px; /* Opcional: redondea las esquinas del borde */

  color: #54D3D4;

  p{
    color: #FFFFFF;
  }
}

.text-container {
  flex: 1; /* Toma el espacio restante disponible */
  padding-right: 20px; /* Espacio entre el texto y la imagen */
}

.image-container {
  // width: 30%; /* O el ancho que prefieras */
}

.trajectory-image {
  width: 100%; /* Asegura que la imagen se ajuste a su contenedor */
  height: auto; /* Mantiene la relación de aspecto */
  background-color: #FFFFFF;
}

.trajectoryDescription{
  margin-top: 0px;
}

.trajectoryTitle{
  font-size: 2rem;
}

.center-content {
  display: flex;
  flex-direction: column; /* Coloca los elementos uno encima del otro */
  align-items: center; /* Centra horizontalmente */
  padding-top: 50px;

}

.logo-container, .photoPersonal {
  display: flex;
  justify-content: center; /* Centra el contenido dentro de cada contenedor */
  margin-bottom: 1rem; /* Espacio entre las imágenes */
  text-align: center; /* Asegura que el texto (si hay) también esté centrado */
}

.photoPersonal {
  /* Si necesitas más margen entre las imágenes */
  margin: 0; 
}

.photoPersonal img {
  width: 100%; /* Cambia a 100% para asegurarte de que la imagen se ajuste al contenedor */
  height: auto;
  mask-image: radial-gradient(circle, rgba(0, 0, 0, 1) 30%, rgba(0, 0, 0, 0) 72%);
  -webkit-mask-image: radial-gradient(circle, rgba(0, 0, 0, 1) 30%, rgba(0, 0, 0, 0) 72%);
}

/* Si deseas un control adicional sobre los tamaños de las imágenes */
.logo-container img {
  width: 400px; /* Puedes ajustar según tu necesidad */
  height: auto; /* Mantiene la relación de aspecto */
}

.photoPersonal img {
  width: 300px; /* Ajusta según tus necesidades */
  height: auto;
}


/* Responsivo para pantallas más pequeñas */
@media screen and (max-width: 1200px) {

  h1{
      text-align: center;
      font-size: 3rem;
  }
  
  h2{
      text-align: center;
      font-size: 2rem;
  }

  p{
      width: 100%;

  }
  .home-container {
      flex-direction: column;
  }

  .hero-image {
      margin-bottom: 0;
  }

  .text-content {
      order: 2;
      
  }

  .image-content {
      order: 1;
  }

  .social-icons {
      display: flex; 
      align-items: center; 
      justify-content: center; 
      gap: 10px;
  }
}

