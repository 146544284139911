@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

h1 {
    font-family: "Raleway", sans-serif;
    font-optical-sizing: auto;
    font-weight: 300;
    font-style: normal;
    font-size: 5rem;
    animation: fadeIn .4s ease-in-out;


}

h2 {  
    font-family: "Lora", serif;
    font-optical-sizing: auto;
    font-weight: 200;
    font-size: 2.5rem;
    font-style: normal;
    animation: fadeIn .5s ease-in-out;


}

p {
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    font-style: normal;
    font-size: 1.7rem;
    text-align: justify;
    animation: fadeIn .6s ease-in-out;


}
.home-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 30px;
}

.home-container-logo{
    align-items: center;
}

.image-content, .text-content {
    flex: 1;
}

.hero-image {
    display: flex;
    justify-content: center; 
    margin-top: 0rem;
    margin-bottom: -3rem;
    animation: fadeIn .3s ease-in-out;
}

.hero-image img {
    width: 90%;
    height: auto;
    mask-image: radial-gradient(circle, rgba(0, 0, 0, 1) 50%, rgba(0, 0, 0, 0) 66%);
    -webkit-mask-image: radial-gradient(circle, rgba(0, 0, 0, 1) 50%, rgba(0, 0, 0, 0) 66%);
}

.social-icons{
    animation: fadeIn .7s ease-in-out;
}

/* Responsivo para pantallas más pequeñas */
@media screen and (max-width: 1200px) {

    h1{
        text-align: center;
        font-size: 3rem;
    }
    
    h2{
        text-align: center;
        font-size: 2rem;
    }

    p{
        width: 100%;

    }
    .home-container {
        flex-direction: column;
    }

    .hero-image {
        margin-bottom: 0;
    }

    .text-content {
        order: 2;
        
    }

    .image-content {
        order: 1;
    }

    .social-icons {
        display: flex; 
        align-items: center; 
        justify-content: center; 
        gap: 10px;
    }
}

