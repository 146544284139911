.not-found {
    display: flex;                  // Utiliza flexbox
    flex-direction: column;        // Coloca los elementos en columna
    justify-content: center;       // Centra verticalmente
    align-items: center;           // Centra horizontalmente
    height: 100vh;                 // Toma toda la altura de la ventana
    text-align: center;            // Centra el texto
  }

  .not-found-description{
    text-align: center;
  }
  
  .not-found img {
    margin-top: 20px;             // Espacio entre el texto y la imagen
  }