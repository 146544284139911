.nav-bar {
    background: #323A5B;
    width: 100%;
    top: 0;
    z-index: 3;

    nav {
        display: flex;
        justify-content: space-around;
        align-items: center;
        height: 100%;

        a {
            display: flex; /* Usamos flex para alinear icono y texto */
            align-items: center; /* Alinea el icono y el texto en el centro verticalmente */
            font-size: 20px;
            color: #78A1D1;
            line-height: 51px;
            height: 51px;
            position: relative;
            text-decoration: none;
            text-align: center;

            .nav-text {
                transition: opacity 0.3s ease; /* Solo la opacidad aquí */
                margin-left: 8px; /* Espacio entre el icono y el texto */
                opacity: 0.8; /* Valor inicial para opacidad en estado normal */
            }

            &:hover {
                opacity: 0.8; /* Mantiene la opacidad al hacer hover */

                .nav-text {
                    opacity: 0.8; /* Mantiene la opacidad al hacer hover */

                }

                color: #78A1D1; /* Cambiar color del texto */
                svg {
                    color: #78A1D1; /* Cambiar color del icono */
                }
            }

            svg {
                transition: opacity 0.3s ease; /* Añadir transición para la opacidad del icono */
            }

            &:hover svg {
                opacity: 0.8; /* Mantiene la opacidad al hacer hover */
            }

            &.active {
                .nav-text {
                    opacity: 1; /* Hacer visible cuando está activo */
                }

                color: #E1BF9C; /* Cambiar color del texto cuando está activo */
                
                svg {
                    color: #E1BF9C; /* Cambiar color del icono cuando está activo */
                }
            }

            @media (max-width: 768px) {
                font-size: 15px;
            }
        }

        @media (max-width: 768px) {
            nav {
                a {
                    // font-size: 18px;
                    // margin: 0 0.5rem;
                }
            }
        }

        @media (max-width: 480px) {
            nav {
                height: auto;
                padding: 0;
                a {
                    // font-size: 17px;
                    // margin: 5px 0;
                }
            }
        }
    }
}
