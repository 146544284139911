.works-container {
  text-align: center;

  h1 {
    margin-bottom: 0;
  }

  h2 {
    margin-top: 0;
  }
}

.works-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Muestra 3 columnas por defecto */
  gap: 50px; /* Aumentar el espacio entre las tarjetas */
  justify-content: center;
  justify-self: center;
  justify-items: center;
  margin: 0 auto;
  padding: 0px 20px;
  animation: fadeIn .7s ease-in-out;

  @media (max-width: 800px) {
    grid-template-columns: repeat(2, 1fr); /* Muestra 2 columnas en pantallas medianas */
  }

  @media (max-width: 500px) {
    grid-template-columns: 1fr; /* Muestra 1 columna en pantallas pequeñas */
  }
}

.card {
  position: relative; /* Relativo para el overlay */
  padding: 5px;
  background: #b0b0b0;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden; 
  transition: transform 0.3s;
  width: 95%; /* Ancho de la tarjeta */
}

.image-container {
  position: relative; /* Necesario para que el overlay esté relativo a la imagen */
}

.card-image {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  object-fit: cover;
  transition: transform 0.5s ease; /* Transición suave */
  z-index: 1; /* Asegurar que la imagen esté encima */
}

.card:hover .card-image {
  transform: scale(1.05); /* Efecto de hover solo en la imagen */
  filter: blur(2px); /* Aplica el efecto blur */

}

.tech-overlay {
  position: absolute; /* Posiciona el overlay */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex; /* Usa flexbox para centrar los íconos */
  justify-content: center;
  align-items: center;
  opacity: 0; /* Ocultar por defecto */
  transition: opacity 0.3s ease; /* Transición suave al mostrar el overlay */
  border-radius: 8px; /* Bordes redondeados para el overlay */
}

.card:hover .tech-overlay {
  opacity: 1; /* Mostrar overlay al hacer hover en la tarjeta */
}

.tech-icon {
  width: 40px; /* Ajusta el tamaño del ícono */
  height: 40px;
  margin: 0 5px; /* Espacio entre los íconos */
}

.card-content {
  padding-left: 10px;
  padding-right: 10px;
  text-align: left; /* Alineación del contenido a la izquierda */
}

.card-description {
  font-size: 1.5em;
  color: #0A001B;
  margin: 10px 0;
  text-align: justify;
  margin-top: 0px;
}

.card-title {
  font-size: 1.6em;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  margin: 10px 0;
  color: #0A001B;
}

.icons {
  display: flex;            /* Usa flexbox para alinear los íconos */
  justify-content: space-around; /* Distribuye el espacio entre los íconos */
  align-items: center;     /* Alinea verticalmente los íconos */
  gap: 10px;               /* Espacio entre los íconos */
  margin-top: 10px;        /* Espacio superior opcional */
}

.icons a {
  font-size: 2.5em;          /* Tamaño de los íconos */
  color: #333;             /* Color de los íconos */
  transition: color 0.3s;  /* Transición suave para el color */
}

.icons a:hover {
  color: #007bff;          /* Cambia el color al hacer hover */
}

@media screen and (max-width: 1200px) {
  
  h1{
    font-size: 3rem;
  }

  h2{
    font-size: 1.5rem;
}


}