html {
  font-size: 62.5%;
}

body {
  margin: 0;
  font: 300 11px/1.4 'Coolvetica', 'sans-serif';
  color: #fff;
  background: #0A001B;
  /* overflow: hidden; */
  /* display: block; */ 
  /* -webkit-font-smoothing: antialiased; */
    /* -moz-osx-font-smoothing: grayscale; */
}
